<template>
    <span>
       
    <div v-if="qrCode">
        <img :src="qrCode" alt="QR Code">
    </div>
    <!-- {{ this.data }} -->
</span>
</template>
<script>
import { mapGetters } from "vuex";
import QRCode from 'qrcode';
import { fetchGlobalVariableById } from "@/repo/globalVariables";
export default {
    name: "QRReaderView",
    components: {},
    props: ["data", "value", "isActive", "isDataTableField"],

    computed: {
        ...mapGetters("globalVariables", ["getGlobalVariableById"]),

        selectedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        computedStyles() {
            const styles = {};

            // Set display to flex
            styles.display = 'flex';

            // Set justify-content based on data.styles.labelAlignments
            if (this.data?.styles?.labelAlignments) {
                styles.justifyContent = this.data.styles.labelAlignments;
            }

            // Add other dynamic styles if needed

            return styles;
        },
        getStyle() {
            let inputStyle = "";
            if (this.data.styles) {
                inputStyle += this.data.styles.input_background
                    ? `background-color: ${this.data.styles.input_background}`
                    : "";
            }

            inputStyle += ";";
            // inputStyle = inputStyle + `width: ${this.data.width}px !important;`;
            return inputStyle;
        },
        isDefalutPos() {
            if (this.data.styles && this.data.styles.labelStyle == "right") {
                return false;
            } else {
                return true;
            }

            // return isDefalutPosq;
        },
        getElementStyle() {
            let borderStyle = "";

            if (this.data.styles) {
                let type = this.data.styles.border_type
                    ? this.data.styles.border_type
                    : "solid";
                let size = this.data.styles.border_size
                    ? this.data.styles.border_size + "px"
                    : "0px";
                let color = this.data.styles.border_color
                    ? this.data.styles.border_color
                    : "";
                let font_size =
                    this.data.styles &&
                        this.data.styles.font &&
                        this.data.styles.font.font_size
                        ? this.data.styles.font.font_size
                        : "";
                let bold =
                    this.data.styles &&
                        this.data.styles.font &&
                        (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
                        ? "bold"
                        : "";
                let italic =
                    this.data.styles &&
                        this.data.styles.font &&
                        (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
                        ? "italic"
                        : "";

                borderStyle = "border:" + type + " " + " " + size + " " + color;

                borderStyle += ";";
                borderStyle += this.data.styles.background
                    ? `background: ${this.data.styles.background}`
                    : "";
                borderStyle += ";";
                borderStyle += this.data.styles.label_color
                    ? `color: ${this.data.styles.label_color}`
                    : "";
                borderStyle += ";";
                borderStyle += "font-size:" + font_size + "px;";
                if (bold) {
                    borderStyle += "font-weight:" + bold + ";";
                }
                if (italic) {
                    borderStyle += "font-style:" + italic + ";";
                }
                borderStyle += this.data.styles.label_color
                    ? `color: ${this.data.styles.label_color}`
                    : "";
            }

            return borderStyle;
        }
    },
    data() {
        return {
            qrCode: null,
            validations: [],
            options: [],
            isList: false,
            // isDefalutPosq: true,
            showLabel: true,
            inputTextAlignmentValue: 'left',
            text: "https://esigns.io"
            // validations: [],
            // isDefalutPos: true,
            // showLabel: true
        };
    },
    mounted() {
        // var qrcode = new QRCode("qrcode",
        // "https://www.geeksforgeeks.org");
        // if (this.data.styles) {
        //     if (this.data.styles && this.data.styles.labelStyle) {
        //         if (this.data.styles.labelStyle == "hide") {
        //             this.showLabel = false;
        //         }
        //         // if (this.data.styles.labelStyle == "right") {
        //         //   this.isDefalutPos = false;
        //         // }
        //     }
        // }

        // this.options = this.data.options || [];
        // if (this.data.is_global_variable && this.data.global_variable_id) {
        //     this.setGlobalVariableData();
        // }
        this.generateQRCode();
    },
    methods: {
        generateQRCode() {
            if (this.text) {
                QRCode.toDataURL(this.text, { errorCorrectionLevel: 'H' }, (err, url) => {
                    if (err) throw err;
                    this.qrCode = url;
                });
            }
        },
        inputTextAlignment() {
            return this.data.styles && this.data.styles.inputTextAlignments
                ? this.data.styles.inputTextAlignments
                : 'left'; // Set a default value if not provided
        },
        async setGlobalVariableData() {
            let params = {
                id: this.data.global_variable_id
            };
            // await this.$store.dispatch(
            //     "globalVariables/fetchGlobalVariableById",
            //     params
            // );
            let globalVariable = await fetchGlobalVariableById(params);
            if (globalVariable) {

                if (globalVariable.input_type == "SINGLE_LINE_TEXT") {
                    // this.isList = true;
                    this.data.selectedValue = globalVariable.value;
                    // this.data.is_list = true;
                } else {
                    // this.data.selectedValue = this.getGlobalVariableById.value;
                    // this.data.is_list = false;
                }
            }
        },
        openSettings() {
            this.$emit("settings");
        }
    },
    watch: {
        // isActive: {
        //   immediate: true,
        //   handler(val, oldVal) {
        //   }
        // },
        'data.styles.inputTextAlignments': {
            handler(newVal) {
                // Whenever inputTextAlignments changes, update the computed property.
                this.inputTextAlignmentValue = newVal;
            },
            immediate: true // This will trigger the handler immediately when the component is created
        }
    }
};
</script>

<style lang="scss" ></style>

<style lang="scss" scoped>
.slv {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 32px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;

    &:hover {
        border-color: #409EFF;
        /* Change the border color on hover */
    }

    &:focus {
        border-color: #409EFF;
        /* Change the border color when focused */
    }

    &::placeholder {
        color: #ccc;
        /* Use your desired lighter gray color here */
    }
}
</style>